.dashboard_layout .Polaris-Navigation {
  width: inherit;
  min-width: 60px !important;
  max-width: 15rem !important;
  transition: width ease-in 0.2s;
}

.dashboard_layout .Polaris-Navigation__PrimaryNavigation {
  scrollbar-gutter: auto !important;
}

.dashboard_layout .Polaris-Navigation__ItemWrapper {
  padding: 0 !important;
}

.dashboard_layout .Polaris-Icon {
  margin: 0 !important;
}

.Polaris-ProgressBar--sizeMedium {
  height: 8px;
}

.table_tab_filter {
  height: 0;
  opacity: 0;
  transition: 0;
}

.table_tab_filter.visible {
  height: 100%;
  opacity: 1;
  transition: opacity 0.4s ease-out;
}

.table_search_filter {
  overflow: hidden;
  height: 0;
}

.table_search_filter.visible {
  height: 82px;
  transition: height 0.1s ease-out;
}

.table_search_filter.visible.no_tab {
  height: 42px;
  transition: height 0.1s ease-out;
}

.Polaris-IndexTable__TableHeading--second {
  left: 40px !important;
}

@media screen and (min-width: 600px) {
  .Polaris-IndexTable__TableHeading--second {
    left: 38px !important;
  }
}

.Polaris-IndexTable__TableHeading--unselectable {
  left: 0px !important;
}

.Polaris-TextField__Input {
  font-size: var(--p-font-size-325);
  line-height: var(--p-font-line-height-500);
}

.Polaris-Filters--hideQueryField .Polaris-Filters__FiltersInner {
  flex: 1 1;
  padding: 0.375rem var(--p-space-200);
}

body {
  overflow-y: hidden;
  height: 100%;
}

main {
  overflow-y: auto;
  position: fixed;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
}

.Polaris-Frame--hasTopBar .Polaris-Frame__Main {
  padding-top: 0px !important;
}

:root {
  --pg-navigation-width: 60px;
  --p-color-bg-surface-success: #eaf4ff !important;
  --p-color-bg-surface-success-active: #eaf4ff !important;
  --p-color-bg-surface-success-hover: #eaf4ff !important;
  --toastify-toast-bd-radius: var(--p-border-radius-300) !important;
  --toastify-toast-width: max-content !important;
  --pc-app-provider-scrollbar-width: 0px !important;
}

.Toastify__toast {
  max-width: 600px !important;
  gap: var(--p-space-200);
}

.settings-nav {
  height: calc(100% - 48px);
}

.settings-nav .Polaris-Navigation {
  max-width: 100%;
  background-color: white;
  border-radius: 0 0 var(--p-border-radius-400) var(--p-border-radius-400);
  min-height: calc(100% - 24px);
  border-color: var(--p-color-border);
  border-width: 0.5px;
  border-style: solid;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}
.settings-nav .Polaris-Navigation .Polaris-Navigation__PrimaryNavigation:hover {
  background-color: white;
}

.settings-nav .Polaris-Navigation .Polaris-Navigation__PrimaryNavigation {
  border-radius: 0 0 var(--p-border-radius-400) var(--p-border-radius-400);
}

html {
  scroll-behavior: smooth;
  height: 100%;
  overflow: auto;
}

.Polaris-PositionedOverlay {
  z-index: 1500;
}

td {
  font-weight: 450;
  font-size: 13px;
  line-height: 20px;
  color: #303030;
}
.text-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}
.text-button:hover {
  text-decoration: underline;
  text-decoration-color: #a5b4e6;
  text-decoration-thickness: 1px;
}
.black-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}
.black-button:hover {
  text-decoration: underline;
  text-decoration-color: #000000;
  text-decoration-thickness: 1px;
}

.bottom-padding {
  padding-bottom: calc(75px + 3 * 24px + 50px);
}

.Polaris-OptionList-Option {
  font-size: 13px;
  line-height: 20px;
  color: #303030;
}

.status-tag-wrapper .Polaris-Tag {
  background-color: transparent;
  padding-inline: var(--p-space-200);
  padding-block: var(--p-space-100);
}

.status-tag-wrapper .Polaris-Tag__Text {
  font-weight: 550;
}

.success-bg {
  background-color: var(--Color-bg-fill-fill-success-secondary, #e9f1ef);
  border-radius: var(--p-border-radius-200);
}

.success-bg .Polaris-Tag__Text {
  color: 'text.success';
}

.disabled-bg {
  background-color: var(--p-color-bg-fill-tertiary);
  border-radius: var(--p-border-radius-200);
}

#order-table .Polaris-IndexTable__TableCell:last-child {
  min-width: 200px;
  max-width: 200px;
  width: 200px;
}

.Polaris-Thumbnail--sizeMedium {
  width: inherit;
}

.Polaris-IndexTable__TableRow--child .Polaris-IndexTable-Checkbox__Wrapper {
  display: none;
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--child {
  --pc-index-table-checkbox-padding-left: none;
}

.sorting-progress .Polaris-ProgressBar--tonePrimary {
  --pc-progress-bar-indicator: var(--p-color-bg-fill-inverse-active);
}

.Polaris-Button--sizeMicro {
  --pc-button-padding-inline: var(--p-space-100);
}

.larger_button {
  margin-top: 20px !important;
}

.larger_button .Polaris-Button--sizeLarge {
  min-height: var(--p-height-1000) !important;
}

.subdued_button .Polaris-Button {
  background-color: var(--p-color-bg-fill-tertiary) !important;
  --pc-button-box-shadow: none !important;
}
.subdued_button :hover {
  background-color: var(--p-color-bg-fill-tertiary-hover) !important;
}

.MuiFormHelperText-root {
  margin-left: 0px !important;
}

.customAlertStatusButton {
  background-color: #fde1e6 !important;
  color: #902333 !important;
}

.Polaris-Page-Header__PrimaryActionWrapper {
  margin-left: var(--p-space-150);
}

.Polaris-Popover {
  margin: var(--p-space-150) var(--p-space-200) var(--p-space-400);
}

.polaris-card {
  background-color: #fff;
  border: 1px solid #dfe3e8;
  border-radius: 12px;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
  padding: 1rem;
  width: 100%;
  cursor: pointer;
}

.highlighted-polaris-card {
  background-color: var(--p-color-bg-surface-secondary-active);
}
.Polaris-Badge--toneSuccess {
  background-color: #e9f1ef;
  font-weight: 550;
}

#banner-critical-change .Polaris-Banner {
  --p-color-bg-fill-critical: rgba(240, 58, 85, 1);
}
